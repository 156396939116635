* {
  font-family: 'Open Sans', sans-serif;
}

#root,
#root > div {
  height: 100%;
  /* overflow: scroll; */
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.vertical-timeline-element-content {
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: 0em;
  padding: 0 !important;
  border: 7px black !important;
}

.vertical-timeline-element {
  margin-top: 20px;
  margin-bottom: 20px;
}
.vertical-timeline::before {
  top: 40px;
  height: 80%;
}
